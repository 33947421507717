<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/account.css";
</style>
<style type="text/css" scoped>

</style>
<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  // import Multiselect from "vue-multiselect";


  /**
   * PersonData--imgage
   */
  export default {
    components: {
      Layout,
      PageHeader,
      // Multiselect


    },
    data() {
      return {
        title: "部门设置",
        items: [{
            text: "用户首页",
            href: "/"
          },
          {
            text: "控制面板",
            active: true
          }
        ],
        // value: "选择部门",
        // options: [
        //   "Alaska",
        //   "Hawaii",
        //   "California",
        //   "Nevada",
        // ],
        defaultvalue: 1,
        currentpage: 1,
        tableList: [{
            id: 1,
            bmbm: "4354",
            bmmc: "人事处",
            zh: "832627",
            xm: "王小川",
            ssbm: "人事处",
            bmjj: "部门简介",

          },
          {
            id: 2,
            bmbm: "4354",
            bmmc: "人事处",
            zh: "832627",
            xm: "王小川",
            ssbm: "人事处",
            bmjj: "部门简介",

          },
          {
            id: 3,
            bmbm: "4354",
            bmmc: "人事处",
            zh: "832627",
            xm: "王小川",
            ssbm: "人事处",
            bmjj: "部门简介",
          
          },
        ],
        
      };
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body ">
            <div class="pb-3 border-dash  check-table-top">
              <div class="flexList" style="flex: 1;">
                <input placeholder="关键字" class="h30 form-control border-blue mr-2 w-15" maxlength="50" />
                <button type="button" class="btn btn-info h30 flexList"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <b-button v-b-modal.department variant="outline-info" class="flexList w-md  condition ml-2"><i
                    class="iconfont icon-plus-square mr-2"></i>添加新部门</b-button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"><i class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"><i class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table  light-table table-hover table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width:3%">序号</th>
                    <th  style="width:5%">部门编码</th>
                    <th>部门名称</th>
                    <th  style="width:8%">负责人账号</th>
                    <th  style="width:5%">负责人</th>
                    <th style="width:8%">所属部门 </th>
                    <th style="width:25%">部门简介 </th>
                    <th style="width:3%">编辑 </th>
                    <th style="width:3%">删除</th>

                  </tr>
                </thead>
                <tbody>
                  <tr v-for="obj in tableList" :key="obj.id">
                    <td>{{obj.id}}</td>
                    <td>{{obj.bmbm}}</td>
                    <td> {{obj.bmmc}}</td>
                    <td>{{obj.zh}}</td>
                    <td class="text-info">{{obj.xm}}</td>
                    <td >{{obj.ssbm}}</td>
                    <td>{{obj.bmjj}}</td>

                    <td class="tab-icon"><i class="iconfont icon-riLine-file-edit-line mr-2"></i></td>
                    <td class="tab-icon">
                      <i class="iconfont icon-riLine-delete-bin-line mr-2"></i>
                    </td>
                  </tr>
                  
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <div class="all-count mr-4">共有 100条，20条/页，前往<div class="link-page m-2"></div>页</div>
              <b-pagination v-model="defaultvalue" :total-rows="50" :per-page="10" aria-controls="my-table">
              </b-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗添加部门 -->
    <b-modal id="department" centered size="m" title="添加新部门" title-class="font-18" hide-footer>
      <div>

        <div class="d-flex check-distri mb-3"><label>部门编码：</label>
          <div class=" col-sm-10 p-0 flexList">
            <input type="text" name="" id="" value="" placeholder="单行输入" class="form-control w-70 h30 " />

          </div>
        </div>
        <div class="d-flex check-distri mb-3"><label>部门名称：</label>
          <div class="col-sm-10  p-0">
            <input type="text" name="" id="" value="" placeholder="单行输入" class="form-control w-70 h30 " />



          </div>

        </div>
        <div class="d-flex check-distri mb-3"><label>所属部门：</label>
          <div class="col-sm-10  p-0">
            <!-- <multiselect v-model="value" :options="options" :multiple="true" class="w-70" :max-height="200"></multiselect> -->
            <select name="" class="form-control form-select w-70">
              <option value="">选择部门</option>
            </select>
            <!-- <b-dropdown size="sm"  variant="outline-light" style="width: 272px;" >
              <template v-slot:button-content class="form-control">
                
                <i class="mdi mdi-chevron-down"></i>
              </template>
              <b-dropdown-item-button>通过</b-dropdown-item-button>
              <b-dropdown-item-button>不通过</b-dropdown-item-button>
              <b-dropdown-item-button>待审核</b-dropdown-item-button>
            </b-dropdown> -->
          </div>
        </div>
        <div class="d-flex check-distri mb-3"><label>负责人：</label>
          <div class="col-sm-10 pr-0 flexList"><input type="text" name="" id="" value="" placeholder="单行输入"
              class="form-control w-40 h30 " />
            <b-button variant="outline-info" class="flexList  condition ml-2 h30"><i
                class="iconfont icon-plus-circle mr-2" style="font-size: 24px;"></i>选择用户</b-button>
          </div>
        </div>
        <div class="d-flex check-distri mb-3"><label>部门简介：</label>
          <div class="col-sm-10 p-0"> <textarea rows="3" class="form-control w-100  line3"
              placeholder="审核备注，如有可输入"></textarea></div>
        </div>

      </div>

      <div class="text-center mt-3"><button type="button" class="btn btn-info h30  mr-2 w-lg">提交操作</button>
        <button type="button" class="btn btn-secondary h30  mr-2 w-lg">取消操作</button>
      </div>
    </b-modal>
  </Layout>
</template>
